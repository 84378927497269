import { useState, useEffect } from 'react';
import { checkSupportsNotifications } from '../utils/check-supports-notification';

export function useNotificationPermission() {

  const [supportsNotifications, setSupportsNotifications] = useState(checkSupportsNotifications());
  const [permission, setPermission] = useState(supportsNotifications ? Notification.permission : null);

  const [asking, setAsking] = useState(false);
  const [error, setError] = useState<unknown>(null);

  // Check permission every second
  useEffect(() => {
    const recheckPermission = () => {
      
      // Check supports notifications
      setSupportsNotifications(checkSupportsNotifications());

      if (!checkSupportsNotifications()) {
        return
      }

      setPermission(Notification.permission);
    }
    const timeout = setInterval(recheckPermission, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const askPermission = async () => {

    try {
      if (!checkSupportsNotifications()) {
        return
      }

      const permission = await Notification.requestPermission() 
      setPermission(permission);

    } catch(e) {

      setError(e);

    } finally {

      setAsking(false);

    }
  }

  return {
    supportsNotifications,
    hasPermission: permission === 'granted',
    permanentlyDenied: permission === 'denied',
    asking,
    error,
    askPermission
  }
}