import { useIOSDeviceAndVersion } from "../hooks/useIOSDeviceAndVersion";
import { useISPWA } from "../hooks/useISPWA";
import PWAPrompt from 'react-ios-pwa-prompt'
import { useState } from "react";
import { Button } from "antd";

export function IOSPwaPromptButton({ children } : { children: React.ReactNode }) {
  const { isIOS } = useIOSDeviceAndVersion();
  const isPWA = useISPWA();
  const [isShown, setIsShown] = useState(false);

  if (!isIOS || isPWA) {
    return null;
  }

  return <>
    <PWAPrompt 
      isShown={isShown}
      onClose={() => {
        setIsShown(false);
      }}
      appIconPath="/maskable_icon_x192.png"
      copyDescription="This website has app functionality. Add it to your home screen to use it in fullscreen."
    />
    <Button onClick={() => setIsShown(true)}>
      { children }
    </Button>
  </>
}