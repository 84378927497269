const FIREBASE_NEW_CHATROOM_MESSAGE_EVENT_TYPE = 'chatroom_message'
const FIREBASE_NEW_DM_REQUEST_EVENT_TYPE = 'dm_request'
const FIREBASE_DM_REQUEST_ACCEPTED_EVENT_TYPE = 'dm_request_accepted'

/**
 * Dont forget to update this array when adding new event types.
 */
const ALL_EVENT_TYPES = [
  FIREBASE_NEW_CHATROOM_MESSAGE_EVENT_TYPE,
  FIREBASE_NEW_DM_REQUEST_EVENT_TYPE,
  FIREBASE_DM_REQUEST_ACCEPTED_EVENT_TYPE,
];

/**
 * Data payload format for new chatroom message notification.
 */
export type FirebaseNewMessageNotificationData = {
  type: typeof FIREBASE_NEW_CHATROOM_MESSAGE_EVENT_TYPE,
  title?: string | null;
  body?: string | null;
  message_id: string;
  chatroom_id: string;
  sender_id?: string | null;
  collapse_key?: string | null,
}

export function isFirebaseNewMessageNotificationData(data: unknown) : data is FirebaseNewMessageNotificationData {
  if (!isFirebaseNotificationData(data)) {
    return false;
  }
  return data.type === FIREBASE_NEW_CHATROOM_MESSAGE_EVENT_TYPE;
}

/**
 * Data payload format for new dm request notification.
 */
export type FirebaseNewDMRequestNotificationData = {
  type: typeof FIREBASE_NEW_DM_REQUEST_EVENT_TYPE,
  title?: string | null;
  body?: string | null;
  chatroom_id: string;
  collapse_key?: string | null,
}

export function isFirebaseNewDMRequestNotificationData(data: unknown) : data is FirebaseNewDMRequestNotificationData {
  if (!isFirebaseNotificationData(data)) {
    return false;
  }
  return data.type === FIREBASE_DM_REQUEST_ACCEPTED_EVENT_TYPE;
}


/**
 * Data payload format for dm request acceptednotification.
 */
export type FirebaseDMRequestAcceptedNotificationData = {
  type: typeof FIREBASE_DM_REQUEST_ACCEPTED_EVENT_TYPE,
  title?: string | null;
  body?: string | null;
  chatroom_id: string;
  collapse_key?: string | null,
}

export function isFirebaseDMRequestAcceptedNotificationData(data: unknown) : data is FirebaseDMRequestAcceptedNotificationData {
  if (!isFirebaseNotificationData(data)) {
    return false;
  }
  return data.type === FIREBASE_NEW_DM_REQUEST_EVENT_TYPE;
}

/**
 * 
 * Format of data payload, sent by server
 * in firebase notification.
 * 
 * Its a union of different data formats used by the server.
 * 
 */
export type FirebaseNotificationData = FirebaseNewMessageNotificationData
  | FirebaseNewDMRequestNotificationData
  | FirebaseDMRequestAcceptedNotificationData

export function isFirebaseNotificationData(data: unknown) : data is FirebaseNotificationData {
  return data !== null
    && typeof data === 'object'
    && Object.hasOwn(data, 'type')
    && ALL_EVENT_TYPES.includes((data as Record<string, any>).type);
}
