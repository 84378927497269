import { isFirebaseDMRequestAcceptedNotificationData, isFirebaseNewDMRequestNotificationData, isFirebaseNewMessageNotificationData } from "../../api/extra-types/firebase-notification-data";
import { useNotificationClickEvent } from "../../notifications/hooks/use-notification-click-event";
import { useAppDispatch } from "../../redux/hooks";
import { setSelectedChatroomId } from "../redux/chat.slice";

export function useHandleChatNotificationClick() {
  const dispatch = useAppDispatch();
  
  useNotificationClickEvent((data) => {
    // User clicked on notification

    // User clicked on new message notification
    // So we open that chatroom.
    if (isFirebaseNewMessageNotificationData(data)) {
      const newChatroomId = data.chatroom_id;
      dispatch(setSelectedChatroomId(newChatroomId));
    }

    // User clicked on dm request notification
    // So we open that chatroom.
    if (isFirebaseNewDMRequestNotificationData(data)) {
      const newChatroomId = data.chatroom_id;
      dispatch(setSelectedChatroomId(newChatroomId));
    }

    // User clicked on dm request notification
    // So we open that chatroom.
    if (isFirebaseDMRequestAcceptedNotificationData(data)) {
      const newChatroomId = data.chatroom_id;
      dispatch(setSelectedChatroomId(newChatroomId));
    }
  });
}