import Flex from "../../shared/components/Flex";
import { NotificationFilled } from '@ant-design/icons';
import { Alert, Button, Card, Typography } from "antd";
import { useNotificationPermission } from "../hooks/use-notification-permission";
import { useIOSDeviceAndVersion } from "../../shared/hooks/useIOSDeviceAndVersion";
import { IOSPwaPromptButton } from "../../shared/components/IOSPwaPromptButton";

export function AskNotificationPermission() {
  const { hasPermission, permanentlyDenied, askPermission, asking, supportsNotifications } = useNotificationPermission();
  const { isIOS } = useIOSDeviceAndVersion();

  if (hasPermission) {
    return null;
  }

  if (!supportsNotifications) {
    return <Card>
    <Flex flexDirection="row" gap={16}>
      <Flex flex={0}>
        <NotificationFilled 
          style={{ fontSize: 20 }}
        />
      </Flex>
      <Flex flexDirection="column" alignItems="flex-start">
        <div>
          <Typography.Paragraph style={{ 
              // @ts-ignore 
              textWrap: 'balance' 
            }}>
            Notifications are not supported in your browser.
            {
              isIOS && (
                <>
                  To enable push notifications and stay updated with real-time trade signals, add this app to your home screen.
                </>
              )
            }
          </Typography.Paragraph>
        </div>
        {
          
          isIOS && (
            <IOSPwaPromptButton>
              Add to Home Screen
            </IOSPwaPromptButton>
          )
        }
      </Flex>
    </Flex>
  </Card>;
  }

  return <Card>
    <Flex flexDirection="row" gap={16}>
      <Flex flex={0}>
        <NotificationFilled 
          style={{ fontSize: 20 }}
        />
      </Flex>
      <Flex flexDirection="column" alignItems="flex-start">
        <div>
          <Typography.Paragraph style={{ 
              // @ts-ignore 
              textWrap: 'balance' 
            }}>
            Allow notifications. To get notified about signal and chat messages.
          </Typography.Paragraph>
        </div>
        {
          permanentlyDenied ? (
            <Alert
              type="info"
              message='Notifications are denied. Please enable it from browser settings'
            />
          ) : (
            <Button 
              onClick={askPermission} 
              loading={asking}  
              type="primary"
            >
              Allow Notifications
            </Button>
          )
        }
      </Flex>
    </Flex>
  </Card>
}