import { isFirebaseDMRequestAcceptedNotificationData, isFirebaseNewDMRequestNotificationData } from "../../api/extra-types/firebase-notification-data";
import { useNewNotificationEvent } from "../../notifications/hooks/use-new-notification-event";
import { useAppDispatch } from "../../redux/hooks";
import fetchMyChatrooms from "../redux/thunks/fetch-my-chatrooms.thunk";
import { fetchUnreadMessageCount } from "../redux/thunks/fetch-unread-message-count.thunk";

export function useHandleChatNotification() {
  const dispatch = useAppDispatch();

  useNewNotificationEvent((data) => {
    if (isFirebaseNewDMRequestNotificationData(data) || isFirebaseDMRequestAcceptedNotificationData(data)) {
      dispatch(fetchMyChatrooms())
      dispatch(fetchUnreadMessageCount())
    }
  });
}