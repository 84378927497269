import { useEffect } from "react";
import { FirebaseNotificationData, isFirebaseNewMessageNotificationData, isFirebaseNotificationData } from "../../api/extra-types/firebase-notification-data";
import { isCandefiNewNotificationEvent } from "../../service-worker-events/CandefiNewNotificationEvent";

export function useNewNotificationEvent(onNewNotification: (payload: FirebaseNotificationData) => void) {

  useEffect(() => {

    const serviceWorkerListener = (e: MessageEvent<any>) => {
      const data = e.data;  
      
      // New notification message from firebase
      // Firebase would only send this if atleast one tab is active
      const isFcmNewNotificationMessage = data.isFirebaseMessaging === true && data.messageType === 'push-received';
      if (isFcmNewNotificationMessage) {
        const notificationData = data.data;
        if (isFirebaseNotificationData(notificationData)) {
          onNewNotification(notificationData);          
        }
        return
      }

      // New message event sent by our service worker
      // When no tab, our service worker would send data instead of firebase.
      if(isCandefiNewNotificationEvent(data)) {
        const nofificationData = data.data;
        if (isFirebaseNotificationData(nofificationData)) {
          onNewNotification(nofificationData);
        }
        return
      }
    }

    const supportsServiceWorker = 'serviceWorker' in navigator;
    if (!supportsServiceWorker) {
      return;
    }

    navigator.serviceWorker.addEventListener('message', serviceWorkerListener);

    return () => {
      navigator.serviceWorker.removeEventListener('message', serviceWorkerListener)
    }

  }, [onNewNotification]);

  return null;
}