import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ChatroomSubscription } from "../chatroom-subscription";
import { ChatroomDetailPage } from "./chatroom-detail-page";
import { MyChatroomsPage } from "./my-chatrooms-page";
import { initializeChatState } from "../redux/thunks/initialize-chat-state";
import { CopyTradePopup } from "./popups/copy-trade-popup";
import { CreateDMChatroomPopup } from "./popups/create-dm-chatroom-popup";
import { DMPrefsPopup } from "./popups/dm-prefs-popup";
import { GroupPrivacySettingsPopup } from "./popups/group-privacy-settings-popup";
import { chatroomsSelector, currentChatroomSelector } from "../redux/chat.selectors";
import { ChatroomMembersPopup } from "./popups/chatroom-members-popup";
import { ChatMessageImageUploadPopup } from "./popups/chat-message-image-upload-popup";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { SignalsFormatPopup } from "./popups/signals-format-popup";
import api from "../../api";
import useSWR from 'swr';
import { setModerators, setMyChatrooms } from "../redux/chat.slice";
import { useHandleChatNotificationClick } from "../notifications/use-handle-chat-notification-click";
import { useHandleChatNotification } from "../notifications/use-handle-chat-noficiation";

export const ChatWidgetNew = () => {

  const { initialized, user } = useAuthUser();
  const dispatch = useAppDispatch();
  const chatrooms = useAppSelector(chatroomsSelector)
  const selectedChatroom = useAppSelector(currentChatroomSelector);

  // Handle notification click
  useHandleChatNotificationClick();

  // Handle new notification
  useHandleChatNotification();

  useSWR('/my-chatrooms', () => api.chat.myChatrooms(), {
    onSuccess(data, key, config) {
      dispatch(setMyChatrooms(data));
    },
  });

  useSWR('/chat/moderators', () => api.chat.moderators(), {
    onSuccess(data) {
      dispatch(setModerators(data))
    }
  })

  useEffect(() => {
    dispatch(initializeChatState());
  }, [initialized, user, dispatch]);

  if (!initialized || !user) {
    return null;
  }

  return <div>
    <CopyTradePopup />
    <CreateDMChatroomPopup />
    <DMPrefsPopup />
    <GroupPrivacySettingsPopup />
    <ChatroomMembersPopup />
    <ChatMessageImageUploadPopup />
    <SignalsFormatPopup />
    {/* websocket subscriptions to each chatroom */}
    {
      chatrooms 
        && chatrooms.map(chatroom => (
          <ChatroomSubscription 
            key={chatroom.id} 
            chatroom={chatroom}
          />
        ))
    }

    {/* list of chatrooms */}
    {
      selectedChatroom === null && <div style={{ height: 500 }}>
        <MyChatroomsPage />
      </div>  
    }

    {
      selectedChatroom !== null && <div style={{ height: 500 }}>
        <ChatroomDetailPage />
      </div>
    }

  </div>
}


