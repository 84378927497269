import { Centrifuge } from "centrifuge";
import { createContext, useContext, useEffect, useState } from "react";
import api from "../../api";
import { useAuthUser } from "../../auth/hooks/useAuthUser";

export type CentContextState = Centrifuge | null

export const CentContext = createContext<CentContextState>(null);

export const CentContextProvider = ({
  children
} : {
  children: React.ReactElement
}) => {

  const { user } = useAuthUser();
  const [cent, setCent] = useState<Centrifuge|null>(null);

  // @ts-ignore
  window.cent = cent;

  useEffect(() => {

    // disconnect on logout
    if (!user && cent) {
      try {
        cent.disconnect()
      } catch (e) {}
      setCent(null);
      return;
    }

    // already disconnect no-op
    if (!user && !cent) {
      return
    }

    // alredy connected no-op
    if (user && cent) {
      return;
    }

    // connect to new client
    let centClient = new Centrifuge([
      { transport: 'websocket', endpoint: process.env.REACT_APP_CENT_WS_URL || '' },
    ], {
      getToken: async (ctx) => {
        const { token: connection_token } = await api.cent.createConnectionToken();
        return connection_token;
      },
      debug: false,
    });

    centClient.connect();
    setCent(centClient);

  }, [user, cent]);

  return <CentContext.Provider value={cent}>
    { children }
  </CentContext.Provider>
}

export const useCent = () => useContext(CentContext);