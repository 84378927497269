import { MessagePayload } from 'firebase/messaging';
import { CandeFiSWEvent, isCandefiSWEvent } from './CandeFiSWEvent';
import { FirebaseNotificationData } from '../api/extra-types/firebase-notification-data';

export const CANDEFI_SW_NEW_NOTIFICATION_EVENT = 'NEW_NOTIFICATION';

/**
 * Service Worker's new notification event
 */

export type CandefiNewNotificationEventData = Omit<MessagePayload, 'data'> & { data: FirebaseNotificationData; };

export type CandefiNewNotificationEvent = CandeFiSWEvent<typeof CANDEFI_SW_NEW_NOTIFICATION_EVENT, CandefiNewNotificationEventData>;

export function isCandefiNewNotificationEvent(event: unknown): event is CandefiNewNotificationEvent {
  if (!isCandefiSWEvent(event)) {
    return false;
  }
  return event.type === CANDEFI_SW_NEW_NOTIFICATION_EVENT;
}
